<template>
    <div class="pb-5">
        <v-title title="Agents - Add"></v-title>
        <title-box>
            <div class="row">
                <div class="col-12">
                    <h1>Add new agent</h1>
                </div>
            </div>
        </title-box>

        <form @submit.prevent="submitForm">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-sm-7">
                        <agent-personal-information class="mt-n4 mb-4" :form="form" :$v="$v"></agent-personal-information>

                        <agent-roles class="mb-4" :form="form"></agent-roles>

                        <agent-artwork-roles class="mb-4" v-model="form.accountSettings.artworkGrades"></agent-artwork-roles>
                        <div class="text-right">
                            <mercur-button class="btn" to="/agents">Cancel</mercur-button>
                            <mercur-button class="btn btn-primary" type="submit" :disabled="loading">Submit</mercur-button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import CONFIG from '@root/config'

import AgentPersonalInformation from '@/components/elements/agents/PersonalInformation'
import AgentRoles from '@/components/elements/agents/Roles'
import AgentArtworkRoles from '@/components/elements/agents/ArtworkRoles'

import FormMixin from '@/mixins/Form'
import { required } from 'vuelidate/lib/validators'

export default {
    name: 'AddAgent',
    mixins: [FormMixin],
    components: { AgentPersonalInformation, AgentRoles, AgentArtworkRoles },
    data () {
        return {
            form: {
                roles: {
                    roleIds: [],
                },
                accountSettings: {
                    artworkGrades: [],
                },
                language: 'en-GB',
            },
        }
    },
    validations: {
        form: {
            firstName: {
                required,
            },
            lastName: {
                required,
            },
            email: {
                required,
            },
            gender: {
                required,
            },
            language: {
                required,
            },
            countryCode: {
                required,
            },
        },
    },
    computed: {
        action () {
            return CONFIG.API.ROUTES.AGENTS.ADD
        },
    },
    methods: {
        submitForm () {
            this.submit(this.action, this.form).then(() => {
                this.$root.$emit('notification:global', {
                    message: 'Agent was added',
                })
                setTimeout(() => {
                    this.$router.push({
                        name: 'AgentsWrapper',
                    })
                }, 2000)
            })
        },
    },
}
</script>
